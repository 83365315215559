import { useCallback, useRef, useState, type KeyboardEvent } from 'react'
import Image from 'next/image'
import { Command as CommandPrimitive } from 'cmdk'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'

import {
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './ui/command'

export type Option = Record<'value' | 'label', string> & Record<string, string>

type AutoCompleteProps = {
  options: Option[]
  emptyMessage: string
  value?: Option
  onValueChange?: (value: Option) => void
  onClear?: () => void
  isLoading?: boolean
  disabled?: boolean
  placeholder?: string
  id?: string
}

export const AutoComplete = ({
  options,
  placeholder,
  emptyMessage,
  value,
  onValueChange,
  onClear,
  disabled,
  id,
  isLoading = false,
}: AutoCompleteProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [isOpen, setOpen] = useState(false)
  const [selected, setSelected] = useState<Option>(value as Option)
  const [inputValue, setInputValue] = useState<string>(value?.label || '')

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (!input) {
        return
      }

      if (!isOpen) {
        setOpen(true)
      }

      if (event.key === 'Enter' && input.value !== '') {
        const optionToSelect = options.find(
          (option) => option.label === input.value
        )
        if (optionToSelect) {
          setSelected(optionToSelect)
          onValueChange?.(optionToSelect)
        }
      }

      if (event.key === 'Escape') {
        input.blur()
      }
    },
    [isOpen, options, onValueChange]
  )

  const handleClear = () => {
    setInputValue('')
    setSelected({ label: '', value: '' })
    if (onClear) {
      onClear()
    }
  }

  const handleBlur = useCallback(() => {
    setOpen(false)
    setInputValue(selected?.label)
  }, [selected])

  const handleSelectOption = useCallback(
    (selectedOption: Option) => {
      setInputValue(selectedOption.label)

      setSelected(selectedOption)
      onValueChange?.(selectedOption)

      setTimeout(() => {
        inputRef?.current?.blur()
      }, 0)
    },
    [onValueChange]
  )

  return (
    <CommandPrimitive
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className="w-auto sm:w-[300px] rounded-md border border-hudson-navy-800 focus-visible:shadow-ring focus-visible:outline focus-visible:ring-offset-ring"
    >
      <div className="flex justify-between pl-2 pr-1 sm:pl-3 sm:pr-2">
        <CommandInput
          ref={inputRef}
          value={inputValue}
          onValueChange={isLoading ? undefined : setInputValue}
          onBlur={handleBlur}
          onFocus={() => setOpen(true)}
          placeholder={placeholder}
          disabled={disabled}
        />
        {inputValue && (
          <Button
            className="self-center !p-0 size-4 lg:size-[18px]"
            size={'circle-xs'}
            variant={'primary'}
            onClick={handleClear}
            data-testid="clear-search-button"
          >
            <Image
              className="size-2"
              src={'/assets/svg/close.svg'}
              alt={'Close Logo'}
              height={14}
              width={14}
            />
          </Button>
        )}
      </div>
      <div className="relative">
        <div
          className={cn(
            'absolute top-1 z-10 w-full rounded-md bg-white drop-shadow-lg animate-in fade-in-0 zoom-in-95',
            isOpen ? 'block' : 'hidden'
          )}
        >
          <CommandList className="rounded-md border border-hudson-navy-800">
            {options.length > 0 && !isLoading ? (
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selected?.value === option.value
                  return (
                    <CommandItem
                      key={option.value}
                      value={option.label}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      onSelect={() => handleSelectOption(option)}
                      className={cn(
                        'flex w-full items-center',
                        !isSelected ? '' : null
                      )}
                    >
                      {option.label}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            ) : null}
            {!isLoading ? (
              <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                {emptyMessage}
              </CommandPrimitive.Empty>
            ) : null}
          </CommandList>
        </div>
      </div>
    </CommandPrimitive>
  )
}
